import React from "react";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import Header from "../components/Header";
import _ from "lodash";
import { graphql, Link } from "gatsby";

import ArticleIcon from "../components/icons/article";
import AwardIcon from "../components/icons/award";
import TalkIcon from "../components/icons/talk";
import JobIcon from "../components/icons/job";

const iconMap = {
  article: <ArticleIcon size="14" className="mr-2" />,
  award: <AwardIcon size="14" className="mr-2" />,
  talk: <TalkIcon size="14" className="mr-2" />,
  job: <JobIcon size="14" className="mr-2" />,
};

export default function Index({ data }) {
  const { group: years } = data.allMarkdownRemark;
  console.log(years);

  return (
    <Layout className="text-green bg-palepeach font-body pt-24 pb-12 px-4 md:px-8">
      <div className="container mx-auto">
        <section>
          <h1 className="headline font-display leading-none pb-0">
            <Header to="/" />
            Notes
          </h1>
        </section>

        {_.orderBy(
          years,
          [
            function (o) {
              return o.nodes[0].frontmatter.year;
            },
          ],
          "desc"
        ).map((year, index) => {
          return (
            <>
              <hr className="divider opacity-25" />
              <section>
                <article className="grid col-3">
                  <div>
                    <h2 className=" pt-0 leading-tight">
                      {year.nodes[0].frontmatter.year}
                    </h2>
                  </div>

                  <div style={{ gridColumn: "span 2" }}>
                    <ul className="text-xl grid col-2 tall-row-gap">
                      {year.nodes.map((post, index) => {
                        return (
                          <li key={index} className="pb-0 flex">
                            <Link
                              to={post.fields.slug}
                              className="leading-tight inline-block home-link text-green"
                            >
                              {post.frontmatter.title}

                              <time className="metadata mb-0 pt-1 text-green opacity-75">
                                {post.frontmatter.date}
                              </time>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </article>
              </section>
            </>
          );
        })}

        <hr className="divider opacity-25" />
        <Footer />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query NotesQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      group(field: frontmatter___year) {
        nodes {
          id
          excerpt
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            year
            title
            description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

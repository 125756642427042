import React from "react";
import { Link } from "gatsby";

const Header = ({ to }) => (
  <nav>
    <Link
      to={to}
      className="text-green headline font-display leading-none nav-finger-link"
    >
      <span style={{ fontSize: "0.75em" }} className="block mb-6 ">
        <span className="nav-finger inline-block">☟</span>
      </span>
    </Link>
  </nav>
);

export default Header;
